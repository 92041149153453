import React from "react"

import "../styles/base.css"
import "../styles/index.css"
import "../styles/namefont.css"

import { Helmet } from "react-helmet"

import WritingSection from "../sections/WritingSection"
import WorkHistorySection from "../sections/WorkHistorySection"
import AboutSection from "../sections/AboutSection"
import HeaderSection from "../sections/HeaderSection"
import InstagramSection from "../sections/InstagramSection"
import Footer from "../components/Footer"

export default () => {
  return (
    <div className="p-4 pb-0 font-sans sm:pb-0 sm:p-8">
      <Helmet
        title="Jeff Barg"
        meta={[
          { name: "charSet", content: "utf-8" },
          {
            name: "description",
            content:
              "New York-based software engineer.  Feel free to reach out over Twitter or LinkedIn!",
          },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <div>
        <HeaderSection />
        <AboutSection />
        <WorkHistorySection />
        <InstagramSection />
        {/* <WritingSection /> */}
      </div>
      <Footer />
    </div>
  )
}
