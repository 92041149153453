import React from "react"
import Section from "../components/Section"
import Img from "gatsby-image"

import key from "weak-key"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "instagram" } }
        limit: 6
        sort: { fields: name, order: DESC }
      ) {
        edges {
          node {
            id
            relativeDirectory
            relativePath
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
              original {
                src
              }
            }
          }
        }
      }
    }
  `)

  let posts = data.allFile.edges.map(x => x.node)

  return (
    <Section
      full
      title="Photos 📷"
      subtitle="Here are various shots I've taken on my iPhone &amp; Fuji X-T4."
    >
      <div className="-mx-4 sm:-mx-8">
        <div className="grid max-w-4xl grid-cols-2 gap-3 p-4 mx-auto sm:px-8 sm:gap-4 sm:grid-cols-3 lg:grid-cols-3">
          {posts.map((post, index) => (
            <a key={key(post)} href={post.childImageSharp.original.src}>
              <Img
                draggable={false}
                fluid={post.childImageSharp.fluid}
                key={key(post)}
                style={{ zIndex: 100 - index }}
                className={`hover:shadow-xl transform hover:scale-110 relative transition-all duration-200 ${
                  index > 7 ? "hidden sm:block" : ""
                }`}
              />
            </a>
          ))}
        </div>
      </div>
    </Section>
  )
}
