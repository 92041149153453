import React from "react"
import Img from "gatsby-image"
import Section from "../components/Section"
import { useStaticQuery, graphql } from "gatsby"

let HoverFixedImage = ({ className, fixed, href }) => {
  return (
    <a href={href} className="inline-block">
      <Img
        fadeIn={false}
        durationFadeIn={0}
        loading="eager"
        draggable={false}
        className={`${className} focus:color-image hover:color-image grayscale select-none focus:scale-105 hover:scale-105 transform transition-all duration-200`}
        fixed={fixed}
      />
    </a>
  )
}

let Job = ({ company, title, dates, image, link, children }) => {
  return (
    <div className="relative w-full">
      <div className="flex flex-row items-start items-center justify-between pt-2 mb-8">
        <div>
          <div className="font-semibold underline">
            <a href={link}>{company}</a>
          </div>
          <div className="text-sm text-gray-700">{title}</div>
          <div className="text-xs font-semibold text-gray-500 uppercase">
            {dates}
          </div>
        </div>
        <div className="">
          <HoverFixedImage href={link} fixed={image} />
        </div>
      </div>
    </div>
  )
}
export default () => {
  const data = useStaticQuery(graphql`
    query {
      amazon_sq: file(relativePath: { eq: "amazon_sq.png" }) {
        childImageSharp {
          fixed: fixed(height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      fundersclub: file(relativePath: { eq: "fundersclub-logo.png" }) {
        childImageSharp {
          fixed: fixed(height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      bw_sq: file(relativePath: { eq: "bridgewater.png" }) {
        childImageSharp {
          fixed: fixed(height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      upenn_sq: file(relativePath: { eq: "penn.png" }) {
        childImageSharp {
          fixed: fixed(height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Section
      title="Work History 👨‍💻"
      subtitle="I'm currently working on something new. Here are my past
              experiences."
    >
      <Job
        company="Amazon"
        title="Software Development Engineer II"
        dates="July 2017 - September 2019"
        image={data.amazon_sq.childImageSharp.fixed}
        link="https://developer.amazon.com/en-US/alexa/science/"
      />
      <Job
        company="Bridgewater Associates, LP"
        title="Summer Technology Associate"
        dates="June 2016 - August 2016"
        image={data.bw_sq.childImageSharp.fixed}
        link="https://bridgewater.com/"
      />
      <Job
        company="University of Pennsylvania"
        title="CIS 160 Teaching Assistant"
        dates="September 2014 - December 2015"
        image={data.upenn_sq.childImageSharp.fixed}
        link="https://upenn.edu/"
      />
      <Job
        company="FundersClub"
        title="Software Engineer Intern"
        dates="May 2015 - August 2015"
        image={data.fundersclub.childImageSharp.fixed}
        link="https://fundersclub.com/"
      />
    </Section>
  )
}
