import React from "react"

export default ({ title, subtitle, children, full }) => (
  <section className={`relative mb-12 ${full ? "" : "max-w-xl mx-auto"}`}>
    {title && (
      <h2 className="max-w-xl mx-auto text-2xl font-semibold text-black font-heading">
        {title}
      </h2>
    )}
    {subtitle && <p className="max-w-xl mx-auto mb-2 text-sm">{subtitle}</p>}
    <div>{children}</div>
  </section>
)
